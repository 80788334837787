export const API_URL = "https://api.secondstage.app"
//export const TOOLPRESS_API = "https://api-tool-press-yn26wrnl4q-ey.a.run.app"
export const TOOLPRESS_API = "https://toolpress-api.2ndstage.app"

export const THEME_CONFIG = {
    mode:"light",
    gradients: {
        default: ['#e05153', '#688675'],
        home_question: ['#e05153', '#688675'],
        applausometro: ['#688675', '#e05153'],
        siNo:['#3a4de4', '#00d8a1'],
        sentiment: ['#3a4de4', '#00d8a1'],
        wordcloud: ['#e05153', '#688675'],
        qna: ['#EA4D95', '#E76E51'],
    },
    primary: '#9d0001',
    secondary: '#263238',
    accent: '#688675',
    text: '#9d0001',
    background:"#fec59a",
    poll:{
        default:"rgba(206,91,92,0.53)",
        answered:"#9d0001",
        correct: '#3A722D',
        wrong: '#DB0A16'
    }
}