import React, {useEffect, useState} from "react";
import {Box, Button, CircularProgress, TextField, Typography} from "@mui/material";
import {useParams} from "react-router-dom";
import {firebase} from "../firebase/clientApp";
import {useLocalStorage} from "../functions/customHook/useLocalStorage";
import {Edit} from "@mui/icons-material";
import axios from "axios";
import {API_URL} from "../config";

export const DisplayName = ({displayName, setDisplayName}) => {
    const {id} = useParams()
    const [name, setName] = useState('')
    const [setting, setSetting] = useState(false)
    const [editing, setEditing] = useState(false)

    useEffect(() => {
        if (displayName) {
            setName(displayName)
        }
    }, [displayName])

    const handleSetDisplayName = (event) => {
        setName(event.target.value)
    }

    const submitDisplayName = async (event) => {
        event.preventDefault()
        setSetting(true)
        let uid = firebase.auth().currentUser.uid
        await axios.post(`${API_URL}/events/${id}/users`, {user: uid, displayName: name})
            .then(res =>
                setDisplayName(name ?? '')
            )
            .catch()
        setEditing(false)
        setSetting(false)
    }

    return (
        <Box>
            {
                (editing || displayName==='' || !displayName) ?
                    <Box pt={5}>
                        <Typography textAlign={'center'} fontWeight={'bold'} fontSize={20} sx={{pb: 2}}>
                            Inserisci la tua e-mail per interagire
                        </Typography>
                        <form onSubmit={submitDisplayName}>
                            <TextField
                                fullWidth
                                required
                                inputProps={{style: {fontSize: '1.2rem'}}}
                                placeholder="E-mail" id="component-simple" value={name} onChange={handleSetDisplayName}/>
                            <Box sx={{width: '10rem', pt: 1, margin: 'auto'}}>
                                {setting ? <CircularProgress/> : <Button variant={'default'} type={'submit'}>
                                    invia
                                </Button>}
                            </Box>
                        </form>
                    </Box>
                    : null
                    /*<Box>
                        <Typography gutterBottom textAlign={'center'} fontWeight={'bold'}>
                            E-mail: {displayName}
                        </Typography>
                        <Button startIcon={<Edit/>} size={'small'} onClick={() => setEditing(!editing)}>
                            modifica
                        </Button>
                    </Box>*/
            }

        </Box>
    )
}
