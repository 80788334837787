import React from "react";
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import {Navigate} from "react-router-dom";

function Home() {
    return (
        <div>
            {/* ODISSEA: */}
            {/*<Navigate to={'/vote/kIXmQ9Wb3j7NOXTfIHn1'}/>*/}
            {/* MIGRAZIONI */}
            {/*<Navigate to={'/vote/FBklgLomESsLWTFfpWdI'}/>*/}
            {/* VI° EDIZIONE */}
            {/*<Navigate to={'/vote/eIPfdgeCAyuq2wIIPB2H'}/>*/}
            {<Navigate to={'/vote/gL74UhGa07rDDkp5Apr5'}/>}
        </div>
    )
}

export default Home