import React from "react";
import {Box} from "@mui/material";

const LogoBox = ({logo}) => {
  return(
      <Box mt={1.5} style={{width:'100%', height:'25%'}} sx={{px: 2}}>
          <img style={{ margin:'auto', width:'100%', height:'100%', objectFit:'contain'}} src={logo}/>
      </Box>
  )
}

export default LogoBox
